import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'


import useJwt from '@src/auth/jwt/useJwt'

const config = useJwt.jwtConfig

// Define a service using a base URL and expected endpoints
export const notificationMessageApi = createApi({
  reducerPath: 'api/NotificationMessage',
  baseQuery: fetchBaseQuery({ 
    baseUrl: `${process.env.REACT_APP_BASE_API}/NotificationMessage`,  
    prepareHeaders(headers, { getState }) {
      headers.set('Authorization', `Bearer ${getState().auth[config.storageTokenKeyName]}`)
      headers.set('Accept', 'application/json') // If not set, RTK query will see PUT and DELETE as failed - if text/plain (defaul) is recieved it sets 'isError'
      return headers
    }
 }),
  endpoints: (builder) => ({
    notificationMessages: builder.query({
      query: () => 'GetNotificationMessages'
    }),
    create: builder.mutation({
      query: (notificationMessageModel) => ({
        url: 'CreateNotificationMessage',
        method: 'POST',
        body: notificationMessageModel
      })
    })
  })
})

export const {
 useNotificationMessagesQuery,
 useCreateMutation
} = notificationMessageApi
