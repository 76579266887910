import { createSlice } from '@reduxjs/toolkit'

// ** Initial State
const initialState = {
  query: ''
}

const navbarSlice = createSlice({
  name: 'navbar',
  initialState,
  reducers: {
    handleSearchQuery(state, action) {
      state.query = action.payload
    }
  }
})

export const { handleSearchQuery } = navbarSlice.actions
export default navbarSlice.reducer