import { createSlice } from '@reduxjs/toolkit'

import useJwt from '@src/auth/jwt/useJwt'

const config = useJwt.jwtConfig

// **  Initial State
const initialState = {
  userData: JSON.parse(window.localStorage.getItem('userData') || '{}'),
  [config.storageTokenKeyName]: window.localStorage.getItem(config.storageTokenKeyName) || '',
  [config.storageRefreshTokenKeyName]: window.localStorage.getItem(config.storageRefreshTokenKeyName) || ''
  
}

const isSuccessLoginAction = action => action.type === 'api/authenticate/executeMutation/fulfilled' && action.meta.arg.endpointName === 'login'

const internalHandleLogin = (state, action) => {
  const data = action.payload
  state.userData = { ...data }
  state[config.storageTokenKeyName] = data.token
  state[config.storageRefreshTokenKeyName] = data[config.storageRefreshTokenKeyName]

  // ** Add to user, accessToken & refreshToken to localStorage
  window.localStorage.setItem('userData', JSON.stringify(data))
  window.localStorage.setItem(config.storageTokenKeyName, data.token)
  window.localStorage.setItem(config.storageRefreshTokenKeyName, data.refreshToken)
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    handleLogin(state, action) {
      internalHandleLogin(state, action)
    },
    handleLogout(state, action) {
      state.userData = {}
      state[config.storageTokenKeyName] = null
      state[config.storageRefreshTokenKeyName] = null

      // ** Remove user, accessToken & refreshToken from localStorage
      window.localStorage.removeItem('userData')
      window.localStorage.removeItem(config.storageTokenKeyName)
      window.localStorage.removeItem(config.storageRefreshTokenKeyName)
    }
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(isSuccessLoginAction, internalHandleLogin)
  }
})

export const { handleLogin, handleLogout } = authSlice.actions
export default authSlice.reducer