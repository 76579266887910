import { createSlice } from '@reduxjs/toolkit'

// ** Initial State
const initialState = {
    filter: {
        regionId: null,
        careHomeId: null,
        grouping: 0
    }
}

const usageSlice = createSlice({
  name: 'pages/usage',
  initialState,
  reducers: {
    setRegionId(state, action) {
      state.filter.regionId = action.payload
    },
    setCareHomeId(state, action) {
        state.filter.careHomeId = action.payload
    },
    setGrouping(state, action) {
      state.filter.grouping = action.payload
    },
    setFilter(state, action) {
        state.filter = action.payload
    },
    setWeekNumber(state, action) {
      state.filter.weekNumber = action.payload
    }
  }
})

export const { setCareHomeId, setRegionId, setGrouping, setFilter, setWeekNumber } = usageSlice.actions
export default usageSlice.reducer