import { configureStore, isRejectedWithValue } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'

// api
import { authenticationApi } from '@src/api/authenticate'
import { resourceApi } from '@src/api/resource'
import { organizationApi } from '@src/api/organization'
import { regionApi } from '@src/api/region'
import { careHomeApi } from '@src/api/careHome'
import { accountApi } from '@src/api/account'
import { statisticApi } from '@src/api/statistic'
import { notificationMessageApi } from '@src/api/notificationMessage'
import rootReducer from '../reducers/rootReducer'
import { handleLogout } from '../reducers/auth'

export const onUnauthorized = (api) => next => action => {
  // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these use matchers!
  
  if (isRejectedWithValue(action) && action.payload.status === 401) {
    next(handleLogout())
  }

  return next(action)
}


export const store = configureStore({
  reducer: {
    ...rootReducer,
    // Add the generated reducer as a specific top-level slice
    [authenticationApi.reducerPath]: authenticationApi.reducer,
    [resourceApi.reducerPath]: resourceApi.reducer,
    [organizationApi.reducerPath]: organizationApi.reducer,
    [regionApi.reducerPath]: regionApi.reducer,
    [careHomeApi.reducerPath]: careHomeApi.reducer,
    [statisticApi.reducerPath]: statisticApi.reducer,
    [notificationMessageApi.reducerPath]: notificationMessageApi.reducer,
    [accountApi.reducerPath]: accountApi.reducer
  },
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) => getDefaultMiddleware()
      .concat(authenticationApi.middleware)
      .concat(resourceApi.middleware)
      .concat(organizationApi.middleware)
      .concat(regionApi.middleware)
      .concat(careHomeApi.middleware)
      .concat(statisticApi.middleware)
      .concat(accountApi.middleware)
      .concat(notificationMessageApi.middleware)
      .concat(onUnauthorized),
  devTools: process.env.NODE_ENV !== 'production'
})

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch)
