// ** ThemeConfig Import
import themeConfig from '@configs/themeConfig'

import { createSlice } from '@reduxjs/toolkit'
import i18n from 'i18next'

// ** Returns Initial Menu Collapsed State
const initialMenuCollapsed = () => {
  const item = window.localStorage.getItem('menuCollapsed')
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : themeConfig.layout.menu.isCollapsed
}

// ** Initial State
const initialState = {
  isRTL: themeConfig.layout.isRTL,
  menuCollapsed: initialMenuCollapsed(),
  menuHidden: themeConfig.layout.menu.isHidden,
  contentWidth: themeConfig.layout.contentWidth,
  language: window.localStorage.getItem('language') || 'de'
}

const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    handleContentWidth(state, action) {
      state.contentWidth = action.payload
    },
    handleMenuCollapsed(state, action) {
      window.localStorage.setItem('menuCollapsed', action.payload)
      state.menuCollapsed = action.payload
    },
    handleMenuHidden(state, action) {
      state.menuHidden = action.payload
    },
    handleRTL(state, action) {
      state.isRTL = action.payload
    },
    changeLanguage(state, action) {
      const lang = action.payload
      state.language = lang
      window.localStorage.setItem('language', lang)
      i18n.changeLanguage(lang)
    },
    handleLanguage(state, action) {
      i18n.changeLanguage(state.language)
    }
  }
})

export const { 
  handleContentWidth, 
  handleMenuCollapsed, 
  handleMenuHidden, 
  handleRTL,
  changeLanguage,
  handleLanguage
} = layoutSlice.actions
export default layoutSlice.reducer