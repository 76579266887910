import { createSlice } from '@reduxjs/toolkit'

// ** Initial State
const initialState = {
    filter: {
        DateFrom: new Date(),
        DateTo: new Date(),
        OrganizationId: null,
        RegionId: null,
        FacilityId: null
    }
}

const exportCsvSlice = createSlice({
  name: 'pages/exportcsv',
  initialState,
  reducers: {
    setStartDate(state, action) {
      state.filter.DateFrom = action.payload
    },
    setEndDate(state, action) {
      state.filter.DateTo = action.payload
    },
    setOrganizationId(state, action) {
      state.filter.OrganizationId = action.payload
    },
    setRegionId(state, action) {
      state.filter.RegionId = action.payload
    },
    setCareHomeId(state, action) {
      state.filter.FacilityId = action.payload
    }
  }
})

export const { setStartDate, setEndDate, setOrganizationId, setCareHomeId, setRegionId } = exportCsvSlice.actions
export default exportCsvSlice.reducer