// ** Reducers Imports
import auth from './auth'
import layout from './layout'
import navbar from './navbar'
import usage from '@src/pages/usage/reducer'
import exportcsv from '@src/pages/exportcsv/reducer'

const rootReducer = {
  auth,
  layout,
  navbar,
  usage,
  exportcsv
}

export default rootReducer
